import React from "react";

const MabForm = ({ mab, actionUrl, formRef }) => {
  return (
    <form id="myform" method="post" action={actionUrl} ref={formRef}>
      <input type="hidden" name="MerchantID" value={mab.merchantId} />
      <input type="hidden" name="MerchantName" value={mab.merchantName} />
      <input type="hidden" name="OrderId" value={mab.orderId} />
      <input type="hidden" name="OrderDetails" value={mab.orderDetail} />
      <input type="hidden" name="Amount" value={mab.amount} />
      <input type="hidden" name="Currency" value={mab.currency} />
      <input type="hidden" name="Timestamp" value={mab.timeStamp} />
      <input type="hidden" name="TimeoutValue" value={mab.timeoutValue} />
      <input type="hidden" name="CallbackURL" value={mab.callBackUrl} />
      <input type="hidden" name="Checksum" value={mab.checksum} />
      <input type="hidden" name="Additonal_param1" value={mab.param1} />
      <input type="hidden" name="Additonal_param2" value={mab.param2} />
      <input type="hidden" name="Additonal_param3" value={mab.param3} />
    </form>
  );
};

export default MabForm;