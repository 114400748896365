import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const KpayPage = () => {
  const params = useParams();

  useEffect(() => {
    const getKPayInfoByInvoice = async (invoiceNumber) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/kbz/kbz-pay/pay?invoiceNumber=${invoiceNumber}`
        );
        if (response.status === 200) {
          const data = await response.data;
          window.location.replace(data.data?.kbzpay_pay_url);
        } else {
          console.log("Bad request");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getKPayInfoByInvoice(params?.id);
  }, [params?.id]);

  return <>Redirecting....</>;
};

export default KpayPage;
