import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./home/page.home";
import KbzDirectPayPage from "./payment-kbz/KbzDirectPayPage";
import KpayPage from "./payment-kbz/KpayPage";
import MabPage from "./payment-mab/page.mab";
import Payment123Page from "./payment123/page.payment123";
import Payment2c2pPage from "./payment2c2p/page.payment2c2p";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/home" element={<HomePage />} />
        <Route exact path="/home/:invoiceNumber" element={<HomePage />} />
        <Route exact path="/mab/:id" element={<MabPage />} />
        <Route exact path="/card-payment/:id" element={<Payment2c2pPage />} />
        <Route exact path="/payment123/:id" element={<Payment123Page />} />
        <Route
          exact
          path="/kbz/direct-pay/:id"
          element={<KbzDirectPayPage />}
        />
        <Route exact path="/kbz/kpay/:id" element={<KpayPage />} />
      </Routes>
    </div>
  );
}

export default App;
