import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Payment2c2pPage = () => {
  const { id } = useParams();

  useEffect(() => {
    const getToken = async (invoiceNumber) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/2c2p/token?invoiceNumber=${invoiceNumber}`
        );
        if (response.status === 200) {
          const data = await response.data;
          if (data.data?.respCode === "0000") {
            window.location.replace(data.data?.webPaymentUrl);
          }
        } else {
          console.log("ERROR");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getToken(id);
  }, [id]);

  return <>redirecting....</>;
};

export default Payment2c2pPage;
