import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import MabForm from "./MabForm";

const MabPage = (props) => {
  const { id } = useParams();
  const [mab, setMab] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    const getMAbInfoByInvoice = async (id) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/mab/checksum?invoiceNumber=${id}`
        );
        if (response.status === 200) {
          const data = await response.data;
          setMab(data.data);
          formRef.current.submit();
        } else {
          console.log("Bad request");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMAbInfoByInvoice(id);
  }, [id]);

  if (mab)
    return (
      <MabForm
        mab={mab}
        formRef={formRef}
        actionUrl={process.env.REACT_APP_MAB_URL}
      />
    );
  return <>Loading...</>;
};

export default MabPage;
