import axios from "axios";
import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Pay123 from "../assets/123_pay.png";
import HeaderImage from "../assets/header.png";
import JCBPay from "../assets/jcb_pay.png";
import KBZ from "../assets/kbz.png";
import Kpay from "../assets/kpay.png";
import MABPay from "../assets/mab_pay.png";
import MasterPay from "../assets/master_pay.png";
import MPUPay from "../assets/mpu_pay.png";
import VisaPay from "../assets/visa_pay.png";

const HomePage = () => {
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const loc = useLocation();
  const params = useParams();

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_HOST}/invoices/`;
    let option = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let invoiceNumber;

    if (params?.invoiceNumber) {
      invoiceNumber = params.invoiceNumber;
      url += params.invoiceNumber;
    } else {
      const params = new URLSearchParams(loc.search);
      const data = params.get("data");

      const buff = Buffer.from(data, "base64").toString();
      const base64ToObject = JSON.parse(buff);
      const { invoice_number, x_access_token } = base64ToObject;

      invoiceNumber = invoice_number;
      url += invoice_number;
      option.headers["x-access-token"] = x_access_token;
    }

    const fetchData = async (id) => {
      try {
        const response = await axios.get(url, option);
        if (response.status === 200) {
          const data = await response.data;
          setInvoiceDetail(data.invoice);
        } else {
          console.log("Bad request");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData(invoiceNumber);
  }, [setInvoiceDetail, loc.search, params?.invoiceNumber]);

  if (!invoiceDetail) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <div id="webapp-wrapper">
          <div id="webapp-content">
            <div className="content-block contact-header">
              {" "}
              <img alt="Header" src={HeaderImage} height="70" />
            </div>
            <div className="content-item">
              <div className="payment_method_content" style={{ marginTop: 20 }}>
                <p>Invoice number : {invoiceDetail?.billIndexInvoiceNumber}</p>
                <p>
                  Total amount : {invoiceDetail?.unpaidAmount}{" "}
                  {invoiceDetail?.preferredCurrency}
                </p>
              </div>

              <div className="phshop" style={{ border: "none" }}>
                <p
                  className="phshop-text"
                  style={{ fontSize: "1.3em !important" }}
                >
                  Please Choose Your Payment Channel
                </p>
              </div>

              <div className="payment_method_content">
                <div className="pay_cards">
                  <h3>Cards</h3>
                  <br />
                  <div className="tab">
                    <Link
                      className="tablinks"
                      to={`/card-payment/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img
                        alt="Vias Pay"
                        className="tablinks_visa"
                        src={VisaPay}
                      />
                    </Link>

                    <Link
                      className="tablinks"
                      to={`/card-payment/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img
                        alt="Master Pay"
                        className="tablinks_master"
                        src={MasterPay}
                      />
                    </Link>

                    <Link
                      className="tablinks"
                      to={`/card-payment/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img
                        alt="JCB pay"
                        className="tablinks_jcb"
                        src={JCBPay}
                      />
                    </Link>

                    <Link
                      className="tablinks"
                      to={`/card-payment/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img
                        alt="MPU pay"
                        className="tablinks_mpu"
                        src={MPUPay}
                      />
                    </Link>
                  </div>
                </div>

                <br />
                <div className="pay_direct">
                  <h3>Direct Pay</h3>
                  <br />
                  <div className="tab">
                    <Link
                      className="tablinks"
                      to={`/mab/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img
                        alt="MAB Pay"
                        className="tablinks_mab"
                        src={MABPay}
                      />
                    </Link>
                    <Link
                      className="tablinks"
                      to={`/kbz/kpay/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img alt="KPay" className="tablinks_kbz" src={Kpay} />
                    </Link>
                    <Link
                      className="tablinks"
                      to={`/kbz/direct-pay/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img alt="KBZ" className="tablinks_kbz" src={KBZ} />
                    </Link>
                  </div>
                </div>

                <br />
                <div className="pay_agent">
                  <h3>Agent {`&`} Apps</h3>
                  <br />
                  <div className="tab">
                    <Link
                      className="tablinks"
                      to={`/payment123/${invoiceDetail?.billIndexInvoiceNumber}`}
                    >
                      <img
                        alt="123 Pay"
                        className="tablinks_gng"
                        src={Pay123}
                      />
                    </Link>
                  </div>
                </div>
                <br />
                <div className="pay_footer">
                  <p style={{ fontWeight: "bold", marginTop: "3%" }}>
                    Contact us:09-966666644
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
