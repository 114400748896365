import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Payment123Page = () => {
  const { id } = useParams();

  useEffect(() => {
    const getToken = async (invoiceNumber) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/123/token?invoiceNumber=${invoiceNumber}`
        );
        if (response.status === 200) {
          const data = await response.data;
          if (data.data.response_code === "00") {
            window.location.replace(data.data?.instructions_url);
          }
        } else {
          console.log("ERROR");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getToken(id);
  }, [id]);
  return <>Redirecting....</>;
};

export default Payment123Page;
